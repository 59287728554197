import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Heading from "../components/PageContainer/Heading";
import MasonryCard from "../components/Masonry/MasonryCard";
import { motion } from "framer-motion";

const Shop = ({ data: { page } }) => {
  const products = page.produkt.product;
  const [expandable, setExpandable] = useState(null);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        type: "tween",
      },
    },
  };

  const childItem = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Layout>
      <Seo title={"Shop - Handla tillbehör till padel"} />
      <div className="container pt-[86px] mt-[60px] lg:mt-24 ">
        <Heading title={"Shop"} centerXs />
        <p className="text-center">
          På denna sida hittar du aktuell information om vilka artiklar som
          finns i den fysiska shoppen i hallen.
        </p>
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-6 pb-12"
        >
          {products.map((item, index) => (
            <motion.div variants={childItem} key={index} style={{display: "flex"}}>
              <MasonryCard
                key={index}
                title={item.title}
                text={item.text}
                image={item?.image}
                product
                shopCard
                expand={expandable === index}
              >
                <p className="mb-0 text-xl flex justify-between w-full items-center">
                  <button style={{fontSize: '80%'}}
                    className={`read-more font-bold text-ua-green-light hover:underline`}
                    onClick={() => expandable !== index ? setExpandable(index) : setExpandable(null)}
                  >
                    
                    {expandable === index ? 'Stäng' : 'Läs mer'}
                  </button>
                </p>
              </MasonryCard>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Layout>
  );
};

export default Shop;

export const pageQuery = graphql`
  query ShopPage {
    page: wpPage(title: { eq: "Shop" }) {
      produkt {
        product {
          price
          text
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  height: 400
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
